.banner-area-two {
    padding: 60px 0;
    background-size: cover;
    position: relative;
  }
  
  .banner-area-two .banner__content-two {
    z-index: 1;
  }
  
  .banner-area-two .shape {
    position: absolute;
    top: -20px;
    right: -40px;
    opacity: 0.8;
  }
  
  .banner-area-two .title {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .banner-area-two .title .svg-icon {
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
  }
  
  .banner-area-two .form-select {
    border-radius: 8px;
    border: 2px solid #00aaa9;
    transition: border-color 0.3s ease;
  }
  
  .banner-area-two .form-select:focus {
    border-color: #0d6efd;
    box-shadow: 0 0 5px rgba(13, 110, 253, 0.5);
  }
  
  .main-btn {
    background-color: #00aaa9;
    border: none;
    padding: 12px 24px;
    font-size: 1.2rem;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .main-btn:hover {
    background-color: #008b88;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .banner-area-two .title {
      font-size: 2rem;
    }
  
    .banner-area-two .form-select {
      font-size: 0.9rem;
    }
  }
  