.custom-breadcrumb {
    background-color: #f8f9fa;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 5px;
    font-size: 16px;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #007bff;
    font-weight: 500;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
    font-weight: 600;
  }
  