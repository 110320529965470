.contact-form-container {
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
    margin-bottom: 30px;
  }
  
  .contact-form-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 8px;
  }
  
  .contact-form-subtitle {
    color: #6c757d;
    font-size: 0.95rem;
    margin-bottom: 24px;
  }
  
  .contact-form .form-group {
    margin-bottom: 16px;
  }
  
  .contact-form .form-control {
    width: 100%;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
  }
  
  .contact-form .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    outline: none;
  }
  
  .contact-form textarea.form-control {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background: #0d6efd;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .submit-button:hover {
    background: #0b5ed7;
  }
  
  .submit-button:disabled {
    background: #6c757d;
    cursor: not-allowed;
  }
  
  .alert {
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 0.95rem;
  }
  
  .alert-success {
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    color: #155724;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
  }