/* Container */
.sd-container {
  background: linear-gradient(135deg, #f6f9fc 0%, #f1f4f9 100%);
  min-height: 100vh;
  padding: 2rem 1rem;
}

.sd-content {
  max-width: 1200px;
  margin: 0 auto;
}

/* Loader */
.sd-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

.sd-loader__spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.sd-loader__text {
  margin-top: 1rem;
  color: #666;
  font-size: 1.1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Error State */
.sd-error {
  text-align: center;
  padding: 3rem 1rem;
}

.sd-error__content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
}

.sd-error__title {
  color: #dc3545;
  margin-bottom: 1rem;
}

.sd-error__button {
  background: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.sd-error__button:hover {
  background: #0056b3;
}

/* Profile Section */
.sd-profile {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
}

.sd-profile__image-container {
  position: relative;
  text-align: center;
}

.sd-profile__image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sd-profile__status {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  white-space: nowrap;
}

.sd-profile__details {
  padding: 1rem 0;
}

.sd-profile__name {
  font-size: 1.75rem;
  color: #2d3748;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
}

.sd-profile__info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.sd-profile__info-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sd-profile__icon {
  color: #4a5568;
  width: 24px;
  height: 24px;
}

.sd-profile__label {
  display: block;
  font-size: 0.875rem;
  color: #718096;
}

.sd-profile__value {
  display: block;
  color: #2d3748;
  font-weight: 500;
}

/* Requirements Section */
.sd-requirements {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.sd-requirements__card {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.sd-requirements__title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.sd-requirements__icon {
  color: #4a5568;
  width: 20px;
  height: 20px;
}

.sd-requirements__grid {
  display: grid;
  gap: 1.5rem;
}

.sd-requirements__item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sd-requirements__item-icon {
  color: #4a5568;
  width: 20px;
  height: 20px;
}

.sd-requirements__label {
  display: block;
  font-size: 0.875rem;
  color: #718096;
}

.sd-requirements__value {
  display: block;
  color: #2d3748;
  font-weight: 500;
}

/* Budget Section */
.sd-budget {
  padding: 1rem 0;
}

.sd-budget__range {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.sd-budget__min,
.sd-budget__max {
  color: #2d3748;
  font-weight: 500;
}

.sd-budget__bar {
  height: 8px;
  background: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
}

.sd-budget__progress {
  height: 100%;
  background: linear-gradient(90deg, #4299e1 0%, #667eea 100%);
  border-radius: 4px;
  width: 100%;
}

/* Map Section */
.sd-map {
  position: relative;
  z-index: 9;
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.sd-map__title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.sd-map__container {
  height: 400px;
  border-radius: 12px;
  overflow: hidden;
}

.sd-map__leaflet {
  height: 100%;
}

/* Toast */
.sd-toast {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  min-width: 300px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.sd-toast__icon {
  width: 20px;
  height: 20px;
  color: #28a745;
  margin-right: 0.5rem;
}

.sd-toast__title {
  color: #2d3748;
}

.sd-toast__body {
  color: #4a5568;
}

/* Fixed Buttons */
.sd-fixed-button {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 1000;
}

.sd-fixed-button a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.sd-fixed-button a:first-child {
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  color: white;
}

.sd-fixed-button a:last-child {
  background: linear-gradient(135deg, #059669, #047857);
  color: white;
}

.sd-fixed-button a:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.sd-fixed-button a:active {
  transform: translateY(0);
}

@media (max-width: 640px) {
  .sd-fixed-button {
    flex-direction: column;
    right: 36%;
    height: 411px;
    left: auto;
    transform: none;
    bottom: -10%;
  }

  .sd-fixed-button a {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .sd-profile {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .sd-fixed-button {
    width: auto;
  }

  .sd-profile__info-item {
    justify-content: center;
  }

  .sd-requirements {
    grid-template-columns: 1fr;
  }

  .sd-map__container {
    height: 300px;
  }
  .sd-form-container{
    height: unset!important;
  }
}

@media (max-width: 480px) {
  .sd-profile__image {
    width: 140px;
    height: 140px;
  }

  .sd-requirements__card {
    padding: 1.5rem;
  }
}

/* Modal Overlay */
.sd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Container */
.sd-modal-container {
  position: relative;

  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  height: 100%;
  max-width: 580px;
  animation: formSlideUp 0.6s ease-out;
}

/* Modal Close Button */
.sd-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #ff0000;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sd-modal-close:hover {
  color: #cc0000;
}

/* Custom styles for the contact form */
.sd-form-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
 
}

.sd-form {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  overflow-y: scroll;

  max-width: 480px;
  position: relative;
  overflow: hidden;
  animation: formSlideUp 0.6s ease-out;
}

.sd-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ff0000;
}

.sd-form-title {
  font-size: 2rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 2rem;
  text-align: center;
}

.sd-input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.sd-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
  transition: color 0.2s;
}

.sd-input,
.sd-textarea {
  width: 100%;
  padding: 0.657rem 1rem 0.657rem 2.3rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  background: white;
  color: #000000;
}

.sd-input:focus,
.sd-textarea:focus {
  border-color: #ff0000;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.1);
}

.sd-input:focus + .sd-icon,
.sd-textarea:focus + .sd-icon {
  color: #ff0000;
}

.sd-textarea {
  height: 120px;
  resize: none;
}

.sd-submit-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #ff0000;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.sd-submit-btn:hover {
  background-color: #cc0000;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 0, 0, 0.2);
}

.sd-submit-icon {
  transition: transform 0.2s;
}

.sd-submit-btn:hover .sd-submit-icon {
  transform: translateX(4px);
}

@keyframes formSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Placeholder styles */
.sd-input::placeholder,
.sd-textarea::placeholder {
  color: #666;
}

/* Focus styles for accessibility */
.sd-input:focus-visible,
.sd-textarea:focus-visible,
.sd-submit-btn:focus-visible {
  outline: 2px solid #ff0000;
  outline-offset: 2px;
}
