/* Custom CSS for ViewPage */
.page-wrapper {
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 30px 0;
  }
  
  .content-wrapper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
    padding: 30px;
    margin-top: 20px;
  }
  
  .breadcrumb {
    background: #fff;
    border-radius: 4px;
    padding: 12px 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .breadcrumb-item a {
    color: #6c757d;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .breadcrumb-item a:hover {
    color: #0d6efd;
  }
  
  .breadcrumb-item.active {
    color: #212529;
    font-weight: 500;
  }
  
  .page-title {
    color: #2c3e50;
    margin-bottom: 30px;
    font-weight: 700;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 15px;
  }
  
  .content-section {
    margin-bottom: 40px;
  }
  
  .section-title {
    color: #343a40;
    font-size: 1.25rem;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .meta-info {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .meta-item {
    margin-bottom: 15px;
  }
  
  .meta-label {
    font-weight: 600;
    color: #495057;
    margin-bottom: 5px;
  }
  
  .meta-value {
    color: #6c757d;
  }
  
  .keyword-tag {
    display: inline-block;
    background: #e9ecef;
    color: #495057;
    padding: 6px 12px;
    border-radius: 20px;
    margin: 0 8px 8px 0;
    font-size: 0.875rem;
    transition: all 0.3s ease;
  }
  
  .keyword-tag:hover {
    background: #dee2e6;
  }
  
  .page-content {
    line-height: 1.8;
    color: #212529;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }
  
  .spinner-border {
    width: 3rem;
    height: 3rem;
    color: #0d6efd;
  }